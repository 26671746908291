import React from 'react';
import { useNavigate } from "react-router-dom";
import { API_URL} from '../api';

import { useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';

export const UserContext = React.createContext();

export const UserDataContext = ({ children }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); //para fechar o menu
  const toast = useToast()

  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [dados, setDados] = React.useState(null);
  const [dadosUser, setdadosUser] = React.useState(null);
  const [idUser, setidUser] = React.useState(null);
  const [NivelUser, setNivelUser] = React.useState(null);
  const [nomeUser, setNomeUser] = React.useState(null);
  const [TokenSI, setTokenSI] = React.useState('');
  const [superUsu, setSuperUsu] = React.useState(false);
  const [IsLogin, setIsLogin] = React.useState(true); // para esconder o header no login


  const token = {
    user: "sidelivery",
    senha: "Si%$#@!",
  };

  React.useEffect(() => {
   const idUser =  window.localStorage.getItem('IDUserOS')
   const nivelUser =  window.localStorage.getItem('NiverUserOS')
   const nomeUser =  window.localStorage.getItem('NomeUserOS')
   if(idUser){
    setidUser(idUser)
    setLogin(true)
   }
   if(nivelUser){
    setNivelUser(nivelUser)
   }
   if(nomeUser){
   setNomeUser(nomeUser)
   }

  },[])

  async function VerificaToken(token) {

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }
    await axios.get(`${API_URL}/verifytoken`, config).then(value => {
      if (!value.statusText === 'ok') {
        navigate('/login')
        throw new Error(`Token invalido`)
      }
    }).catch(value => {
      navigate('/login')
      throw new Error(`Error`)
    })
  }


  

  async function Userlogin(username, password) {
    // console.log(username, password)
    setError(false)
    setLoading(true);

    if ((username === '') || (password === '')) {
      setError(true)
      toast(toastErroDados)
      setLoading(false)
      setLogin(false)
      return
    }


    const postLogin = async () => {
      // console.log(username, password)
      axios.post(`${API_URL}/user`, {
          documento: username,
          senha: password,
        }, 
        {
          auth: {
            username: token.user,
            password: token.senha,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((item)=> {
          // console.log(item?.data)
          if (item.status === 200){
            toast(toastSucessoLogin)
            setError(false)
            if(item?.data?.data?.length > 0){
              setdadosUser(item?.data?.data[0])
              // console.log(item?.data?.data[0]?.NOME_CLI)
              window.localStorage.setItem('IDUserOS', item?.data?.data[0]?.ID_CLI)
              window.localStorage.setItem('NiverUserOS', item?.data?.data[0]?.NIVEL)
              window.localStorage.setItem('NomeUserOS', item?.data?.data[0]?.NOME_CLI)
              setidUser(item?.data?.data[0]?.ID_CLI)
              setNivelUser(item?.data?.data[0]?.NIVEL)
            }
            // setDados(dadosUsuario)
            // setTokenSI(response?.data.token)
            setLogin(true)
            setLoading(false);
            navigate('/')
          } else {
            toast(toastErroDados)
          }

        })
        .catch((error) => {
          console.log(error)
          setLoading(false);
          setError('Cliente Inexistente')
          setLogin(false)
          toast(toastErroReq)
        })

      }
      postLogin()

  }


  function Userlogout() {
    setLogin(false)
    setIsLogin(true)
    window.localStorage.removeItem('IDUserOS')
    toast(toastLogout)
    navigate('/login')
  }


  function formataData(data) {
    if((typeof(data) === 'string') && (data.length > 0)) { 
      const dividido = data.split('-')
    return (`${dividido[2]}/${dividido[1]}/${dividido[0]}`)
    }
      return data   
  }


  return (
    <UserContext.Provider
      value={{
        login, setLogin, loading, error, Userlogout, dados, setDados, superUsu, dadosUser, setdadosUser, idUser, setidUser, NivelUser, setNivelUser,
        Userlogin, Userlogout, setIsLogin, IsLogin, isOpen, onOpen, onClose, formataData
      }}
    >
      {children}
    </UserContext.Provider>
  );
}


const toastErroLogin = {
  title: 'Falha no login.',
  description: "Tente novamente mais tarde.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}

const toastErroDados = {
  title: 'Dados Invalidos.',
  description: "è necessario preencher todos os campos.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}
const toastErroReq = {
  title: 'Erro ao realizar Login',
  description: "Houve um problema ao realizar login",
  status: 'error',
  duration: 4000,
  isClosable: true,
}

const dadosInvalidos = {
  title: 'Email ou senha incorretos!',
  description: "tente novamente ou entre em contato com o ADM do sistema",
  status: 'warning',
  duration: 4500,
  isClosable: true,
}

const toastClienteInativo = {
  title: 'Cliente Inativo.',
  description: "Entre em contato com os administradores do sistema.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}

const toastLogout = {
  title: 'Você se desconectou',
  description: "Ate a proxima!",
  status: 'info',
  duration: 3000,
  isClosable: true,
}

const toastSucessoLogin = {
  title: 'Login Realizado.',
  description: "Tudo certo, vamos ao trabalho!",
  status: 'success',
  duration: 3000,
  isClosable: true,
}